<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <div v-resize="onResize" class="mt-3 ml-1" :class="{ 'mt-5': versionType }">
    <span v-if="versionType">{{ $t('remoteConnection.title') }}</span>
    <v-row v-if="showWarning">
      <v-col cols="12" class="pt-5">
        <span class="font-size-16 warningMessage">
          {{ $t('remoteConnection.passwordIsRequiredForVnc') }}
        </span>
      </v-col>
    </v-row>
    <the-nerve-table
      id="iiotRemoteConnectionManagementTable"
      :columns="columns"
      :empty-state="$t('remoteConnection.table.emptyList')"
      :disable-pagination="true"
      :hide-footer="true"
      :is-search-enabled="false"
      :is-action-menu-enabled="false"
      :is-row-clickable="true"
      store-module="remote-connection"
      :class="{ remoteConnectionTableWorkload: versionType, remoteConnectionTableNode: !versionType }"
      @row-clicked="(params) => editTable(params)"
    />
    <div class="mt-3 importRcInfoMessageDiv">
      <span v-if="showMessage" id="iiotRemoteConnectionManagementImportRcInfoMessage" class="importRcInfoMessage ml-4">
        {{ $t('remoteConnection.importRcInfoMessage') }}
      </span>
    </div>
    <v-row
      v-if="versionType ? canAccess('UI_WORKLOAD:VERSION_CREATE') : canAccess('UI_NODE:CREATE')"
      class="pa-0 mt-1 ml-1"
    >
      <v-col lg="6" md="4" cols="5">
        <div
          v-if="
            versionType
              ? versionType !== 'codesys' && canAccess('UI_WORKLOAD:VERSION_CREATE')
              : canAccess('UI_NODE:CREATE')
          "
          align="center"
          class="d-flex"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-img
                id="iiotRemoteConnectionManagementAddRemoteScreenButton"
                :src="`/img/Add.svg`"
                v-bind="attrs"
                max-height="30px"
                min-height="30px"
                min-width="30px"
                max-width="30px"
                class="cursor-pointer"
                v-on="on"
                @click="showRemoteScreenDialog()"
              />
            </template>
            <span>{{ $t('remoteConnection.addRemoteScreenTooltip') }}</span>
          </v-tooltip>
          <span class="pl-3 text--disabled">
            {{ $t('remoteConnection.addRemoteScreen') }}
          </span>
        </div>
        <div
          v-if="versionType ? canAccess('UI_WORKLOAD:VERSION_CREATE') : canAccess('UI_NODE:CREATE')"
          :class="{ 'mt-6': versionType !== 'codesys', 'pb-6': isMarginVisible && !versionType }"
          align="center"
          class="d-flex"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-img
                id="iiotRemoteConnectionManagementAddRemoteTunnelButton"
                :src="`/img/Add.svg`"
                v-bind="attrs"
                max-height="30px"
                min-height="30px"
                min-width="30px"
                max-width="30px"
                class="cursor-pointer"
                v-on="on"
                @click="showRemoteTunnelDialog()"
              />
            </template>
            <span>{{ $t('remoteConnection.addRemoteTunnelTooltip') }}</span>
          </v-tooltip>
          <span class="pl-3 text--disabled">
            {{ $t('remoteConnection.addRemoteTunnel') }}
          </span>
        </div>
      </v-col>
      <v-col lg="6" md="4" cols="5">
        <div
          v-if="versionType ? canAccess('UI_WORKLOAD:VERSION_VIEW') : canAccess('UI_NODE:VIEW')"
          align="center"
          class="d-flex"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-img
                v-if="route.includes('new') || initialNumberOfConnections === 0"
                id="iiotRemoteConnectionManagementDisabledExportRemoteConnectionsButton"
                :src="`/img/DisabledExportRC.svg`"
                v-bind="attrs"
                max-height="30px"
                min-height="30px"
                min-width="30px"
                max-width="30px"
                class="cursor-pointer"
                v-on="on"
              />
              <v-img
                v-else
                id="iiotRemoteConnectionManagementExportRemoteConnectionsButton"
                :src="`/img/ExportRC.svg`"
                v-bind="attrs"
                max-height="30px"
                min-height="30px"
                min-width="30px"
                max-width="30px"
                class="cursor-pointer"
                v-on="on"
                @click="exportRemoteConnections()"
              />
            </template>
            <span v-if="route.includes('new')">
              {{
                versionType
                  ? $t('remoteConnection.exportDisabledTooltipWorkload')
                  : $t('remoteConnection.exportDisabledTooltipNode')
              }}
            </span>
            <span v-else>
              {{
                initialNumberOfConnections === 0
                  ? $t('remoteConnection.exportDisabledTooltipNoRc')
                  : $t('remoteConnection.exportTooltip')
              }}
            </span>
          </v-tooltip>
          <span class="pl-3 text--disabled">
            {{ $t('remoteConnection.export') }}
          </span>
        </div>
        <div
          v-if="versionType ? canAccess('UI_WORKLOAD:VERSION_CREATE') : canAccess('UI_NODE:CREATE')"
          align="center"
          class="d-flex mt-6"
          :class="{ 'pb-8': isMarginVisible && !versionType }"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-img
                id="iiotRemoteConnectionManagementImportRemoteConnectionsButton"
                :src="`/img/ImportRC.svg`"
                v-bind="attrs"
                max-height="30px"
                min-height="30px"
                min-width="30px"
                max-width="30px"
                class="cursor-pointer"
                v-on="on"
                @click="importRemoteConnections()"
              />
              <input
                id="iiotRemoteConnectionListImportRcInput"
                ref="iiotRemoteConnectionListImportRc"
                type="file"
                style="display: none"
                :accept="['.yaml', 'yml']"
                @click="clearInput"
                @change="importFile"
              />
            </template>
            <span>{{ $t('remoteConnection.importTooltip') }}</span>
          </v-tooltip>
          <span class="pl-3 text--disabled">
            {{ $t('remoteConnection.import') }}
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row v-else class="pa-0 mt-2 ml-1">
      <v-col lg="6" md="4" cols="5">
        <div
          v-if="versionType ? canAccess('UI_WORKLOAD:VERSION_VIEW') : canAccess('UI_NODE:VIEW')"
          align="center"
          class="d-flex"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-img
                v-if="route.includes('new') || initialNumberOfConnections === 0"
                id="iiotRemoteConnectionManagementDisabledExportRemoteConnectionsButton"
                :src="`/img/DisabledExportRC.svg`"
                v-bind="attrs"
                max-height="30px"
                min-height="30px"
                min-width="30px"
                max-width="30px"
                class="cursor-pointer"
                v-on="on"
              />
              <v-img
                v-else
                id="iiotRemoteConnectionManagementExportRemoteConnectionsButton"
                :src="`/img/ExportRC.svg`"
                v-bind="attrs"
                max-height="30px"
                min-height="30px"
                min-width="30px"
                max-width="30px"
                class="cursor-pointer"
                v-on="on"
              />
            </template>
            <span v-if="route.includes('new')">
              {{
                versionType
                  ? $t('remoteConnection.exportDisabledTooltipWorkload')
                  : $t('remoteConnection.exportDisabledTooltipNode')
              }}
            </span>
            <span v-else>
              {{
                initialNumberOfConnections === 0
                  ? $t('remoteConnection.exportDisabledTooltipNoRc')
                  : $t('remoteConnection.exportTooltip')
              }}
            </span>
          </v-tooltip>
          <span class="pl-3 text--disabled">
            {{ $t('remoteConnection.export') }}
          </span>
        </div>
      </v-col>
    </v-row>
    <add-edit-remote-connection-screen
      :node-model="model"
      :version-model="model"
      :edit="editScreenRc"
      :version-type="versionType"
      :has-edit-permission="hasEditPermission"
      :has-create-permission="hasCreatePermission"
      :is-new="isNew"
    />
    <add-edit-remote-connection-tunnel
      :node-model="model"
      :version-model="model"
      :edit="editTunnelRc"
      :version-type="versionType"
      :has-edit-permission="hasEditPermission"
      :has-create-permission="hasCreatePermission"
      :is-new="isNew"
    />
  </div>
</template>
<script>
import { TheNerveTable } from 'nerve-ui-components';
import AddEditRemoteConnectionTunnel from './AddEditRemoteConnectionTunnel.vue';
import AddEditRemoteConnectionScreen from './AddEditRemoteConnectionScreen.vue';

export default {
  components: {
    TheNerveTable,
    AddEditRemoteConnectionTunnel,
    AddEditRemoteConnectionScreen,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    connections: {
      type: Array,
      default: () => [],
    },
    versionType: {
      type: String,
      default: '',
    },
    versionId: {
      type: String,
      default: '',
    },
    workloadConnection: {
      type: Boolean,
      default: false,
    },
    initialNumberOfConnections: {
      type: Number,
      default: 0,
    },
    showWarning: {
      type: Boolean,
      default: false,
    },
    listOfServices: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    editTunnelRc: false,
    editScreenRc: false,
    showMessage: false,
    route: '',
    isMarginVisible: false,
  }),
  computed: {
    columns() {
      return [
        ...(this.versionType === 'docker-compose'
          ? [
              {
                text: this.$t('remoteConnection.table.service'),
                value: 'serviceName',
                sortable: false,
                component: {
                  sfc: () => import('@/components/workloads/helpers/ComposeServiceNameRC.vue'),
                  props: {
                    listOfServices: this.listOfServices,
                  },
                },
              },
            ]
          : []),
        {
          text: this.$t('remoteConnection.table.name'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('remoteConnection.table.type'),
          value: 'headerType',
          sortable: false,
        },
        {
          text: this.$t('remoteConnection.table.port'),
          value: 'port',
          sortable: false,
        },
        {
          text: (this.isNew ? this.hasCreatePermission : this.hasEditPermission) ? this.$t('defaultTable.action') : '',
          value: '',
          width: '0%',
          sortable: false,
          component: {
            sfc: () => import('@/components/remote-connection/RemoteConnectionAction.vue'),
            props: {
              iconsAction: ['Delete'],
              versionId: this.model._id,
              version: this.model,
              versionType: this.versionType,
              hasEditPermission: this.hasEditPermission,
              hasCreatePermission: this.hasCreatePermission,
              isNew: this.isNew,
            },
          },
        },
      ];
    },
    hasEditPermission() {
      return this.versionType ? this.canAccess('UI_WORKLOAD:VERSION_EDIT') : this.canAccess('UI_NODE:EDIT');
    },
    hasCreatePermission() {
      return this.versionType ? this.canAccess('UI_WORKLOAD:VERSION_CREATE') : this.canAccess('UI_NODE:CREATE');
    },
    isNew() {
      return window.location.pathname.split('/').includes('new');
    },
  },
  mounted() {
    this.route = window.location.pathname;
  },
  methods: {
    async showRemoteTunnelDialog() {
      this.editTunnelRc = false;
      this.$store.dispatch('remote-connection/set_rc_tunnel_dialog', true);
      this.$store.dispatch('remote-connection/get_tunnel_rc', { id: false });
    },
    async showRemoteScreenDialog() {
      this.editScreenRc = false;
      this.$store.dispatch('remote-connection/set_rc_screen_dialog', true);
      this.$store.dispatch('remote-connection/get_screen_rc', { id: false });
    },
    editTable(params) {
      if (params.item.type === 'TUNNEL') {
        this.editTunnelRc = true;
        this.$store.dispatch('remote-connection/set_rc_tunnel_dialog', true);
        this.$store.dispatch('remote-connection/get_tunnel_rc', params);
        return;
      }
      this.editScreenRc = true;
      this.$store.dispatch('remote-connection/set_rc_screen_dialog', true);
      this.$store.dispatch('remote-connection/get_screen_rc', params);
    },
    importRemoteConnections() {
      this.$refs.iiotRemoteConnectionListImportRc.click();
    },
    clearInput(e) {
      e.target.value = '';
    },
    async importFile(e) {
      const file = e.target.files[0];
      if (!file) return;
      const formData = new FormData();
      formData.append('file', file);
      try {
        await this.$store.dispatch('remote-connection/import_remote_connections', {
          target: this.versionType || 'node',
          formData,
          isWorkload: !!this.versionType,
        });
        this.showMessage = true;
        if (this.versionType) {
          this.$store.dispatch('workloads/set_save_enabled', true);
        }
      } catch (error) {
        this.$log.debug(e);
      }
    },
    onResize() {
      this.isMarginVisible = window.innerWidth < 1264;
    },
    exportRemoteConnections() {
      const { serialNumber, _id } = this.model;
      let exportUrl = '';
      if (this.versionType) {
        const workloadId = window.location.pathname.split('/')[2];
        exportUrl = `nerve/v3/workloads/${workloadId}/versions/${_id}/export-remote-connections`;
      } else {
        exportUrl = `nerve/v2/node/${serialNumber}/export-remote-connections`;
      }
      window.location = `${window.location.pathname.split('/')[0]}/${exportUrl}`;
    },
  },
};
</script>

<style>
.importRcInfoMessageDiv {
  min-height: 23px !important;
}
#iiotRemoteConnectionManagementImportRcInfoMessage {
  color: var(--v-warning-base);
}
.remoteConnectionTableNode > .v-data-table > .v-data-table__wrapper {
  max-height: 57vh !important;
}
.remoteConnectionTableWorkload > .v-data-table > .v-data-table__wrapper {
  max-height: 50vh !important;
}
</style>
