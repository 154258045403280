<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-form ref="remoteConnectionForm" v-model="valid" @keyup.enter.native="save">
    <nerve-dialog v-model="screenDialog" :retain-focus="false" width="1000" height="1000" persistent>
      <v-card v-resize="onResize" class="rc-screen-dialog">
        <v-card-title>
          {{ edit ? $t('remoteConnection.dialog.editRemoteScreen') : $t('remoteConnection.dialog.newRemoteScreen') }}
        </v-card-title>
        <div>
          <v-card-text :class="{ rcDialog: !isMarginVisible }" class="vertical-scrollbar">
            <v-container class="pt-0">
              <v-row>
                <v-col cols="12" md="6" lg="3">
                  <div class="text-left">
                    {{ $t('remoteConnection.dialog.nerveParameters') }}
                  </div>
                  <v-text-field
                    id="iiotRemoteConnectionScreenDialogNameInput"
                    v-model="remoteConnection.name"
                    :label="$t('remoteConnection.dialog.name')"
                    :rules="rulesForName"
                    required
                  />
                  <v-select
                    id="iiotRemoteConnectionDialogConnectionTypeSelect"
                    v-model="remoteConnection.connection"
                    :items="connectionType"
                    attach
                    :label="$t('remoteConnection.dialog.connectionType')"
                    @input="connectionTypeChange"
                  />
                  <v-text-field
                    id="iiotRemoteConnectionDialogNumberOfConnectionsSelect"
                    v-model.number="remoteConnection.numberOfConnections"
                    :min="REMOTE_CONNECTION.NUMBER_OF_CONNECTIONS_MIN_VALUE"
                    :max="REMOTE_CONNECTION.NUMBER_OF_CONNECTIONS_MAX_VALUE"
                    :label="$t('remoteConnection.dialog.numbOfConnections')"
                    :rules="rulesForNumberOfConnections"
                    attach
                    class="number-class"
                    type="number"
                    required
                    validate-on-blur
                  />
                  <v-select
                    id="iiotRemoteConnectionScreenDialogLocalAcknowledgmentSelect"
                    v-model="remoteConnection.acknowledgment"
                    :label="$t('remoteConnection.dialog.localAcknowledgment')"
                    :items="localAcknowledgment"
                    attach
                  />

                  <v-select
                    v-if="versionType === 'docker-compose'"
                    id="iiotRemoteConnectionScreenDialogComposeServicesSelect"
                    v-model="remoteConnection.serviceName"
                    :label="$t('remoteConnection.dialog.composeServices')"
                    :rules="rulesRequiredField"
                    :items="serviceNames"
                    required
                    attach
                  />
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <div class="mr-8 text-left">
                    {{ $t('remoteConnection.dialog.networkParameters') }}
                  </div>
                  <v-text-field
                    v-if="model === 'nodes'"
                    id="iiotRemoteConnectionDialogHostnameInput"
                    v-model="remoteConnection.hostname"
                    :label="$t('remoteConnection.dialog.hostname')"
                    :rules="rulesForHostname"
                    type="input"
                    required
                    validate-on-blur
                  />
                  <v-text-field
                    v-if="model === 'workloads' && versionType === 'vm' && remoteConnection.connection !== 'VNC'"
                    id="iiotRemoteConnectionDialogHostnameInput"
                    v-model="remoteConnection.hostname"
                    :label="$t('remoteConnection.dialog.vmHostname')"
                    :rules="rulesForHostname"
                    type="input"
                    required
                    validate-on-blur
                  />
                  <v-text-field
                    v-if="model === 'nodes'"
                    id="iiotRemoteConnectionScreenNodesDialogPortInput"
                    v-model.number="remoteConnection.port"
                    class="number-class"
                    type="number"
                    :rules="rulesForPort"
                    :label="$t('remoteConnection.dialog.portOnNode')"
                    required
                    validate-on-blur
                  />
                  <v-text-field
                    v-if="model === 'workloads' && versionType === 'vm' && remoteConnection.connection !== 'VNC'"
                    id="iiotRemoteConnectionScreenDialogWlVmPortInput"
                    v-model.number="remoteConnection.port"
                    class="number-class"
                    type="number"
                    :rules="rulesForPort"
                    :label="$t('remoteConnection.dialog.portOnWorkload')"
                    required
                    validate-on-blur
                  />
                  <v-text-field
                    v-if="model === 'workloads' && (versionType === 'docker' || versionType === 'docker-compose')"
                    id="iiotRemoteConnectionScreenDialogWlDockerPortInput"
                    v-model.number="remoteConnection.port"
                    class="number-class"
                    type="number"
                    :rules="rulesForPort"
                    :label="
                      remoteConnection.connection === 'VNC'
                        ? $t('remoteConnection.dialog.portOnNode')
                        : $t('remoteConnection.dialog.portOnWorkload')
                    "
                    required
                    validate-on-blur
                  />
                  <v-text-field
                    id="iiotRemoteConnectionDialogAutoretryInput"
                    v-model.number="remoteConnection.autoretry"
                    class="number-class"
                    :min="REMOTE_CONNECTION.AUTO_RETRY_MIN_VALUE"
                    :max="REMOTE_CONNECTION.AUTO_RETRY_MAX_VALUE"
                    :label="$t('remoteConnection.dialog.autoretry')"
                    :rules="rulesForAutoRetry"
                    type="number"
                    required
                    validate-on-blur
                  />
                  <v-select
                    v-if="remoteConnection.connection === 'RDP'"
                    id="iiotRemoteConnectionDialogSecurityModeSelect"
                    v-model="remoteConnection.securityMode"
                    :items="securityMode"
                    :label="$t('remoteConnection.securityMode.title')"
                    attach
                  />
                  <div v-if="remoteConnection.connection === 'RDP'">
                    <v-checkbox
                      v-model="remoteConnection.ignoreServerCertificate"
                      data-cy="iiotRemoteConnectionIgnoreServerCertificate"
                      :label="$t('remoteConnection.dialog.ignoreServerCertificate')"
                    />
                  </div>
                </v-col>

                <v-col cols="12" md="6" lg="3">
                  <div class="mr-14 text-left">
                    {{ $t('remoteConnection.dialog.displaySettings') }}
                  </div>
                  <v-select
                    id="iiotRemoteConnectionDialogSwapRedBlueSelect"
                    v-model="remoteConnection.swapRedBlue"
                    :items="swapRedBlue"
                    :label="$t('remoteConnection.dialog.swapRedBlue')"
                    attach
                  />
                  <v-select
                    id="iiotRemoteConnectionDialogCursorSelect"
                    v-model="remoteConnection.cursor"
                    :items="cursor"
                    :label="$t('remoteConnection.dialog.cursor')"
                    attach
                  />
                  <v-select
                    id="iiotRemoteConnectionDialogReadOnlySelect"
                    v-model="remoteConnection.readOnly"
                    :label="$t('remoteConnection.dialog.readOnly')"
                    :items="readOnly"
                    attach
                  />
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <div class="mr-14 text-left">
                    {{ $t('remoteConnection.dialog.authentication') }}
                  </div>
                  <v-text-field
                    v-if="remoteConnection.connection === 'VNC'"
                    id="iiotRemoteConnectionScreenDialogVncPasswordInput"
                    v-model="remoteConnection.password"
                    :label="
                      passwordIsRequired
                        ? $t('remoteConnection.dialog.VNCpassword')
                        : $t('remoteConnection.dialog.password')
                    "
                    :rules="passwordIsRequired ? rulesForInputField : rulesForInputFieldNotRequired"
                    :required="passwordIsRequired"
                    :type="typePasswordField"
                    validate-on-blur
                    @focus="handleType"
                    @blur="handleType"
                  />
                  <div v-if="remoteConnection.connection === 'SSH'">
                    <v-checkbox
                      v-model="remoteConnection.privateKeyFlag"
                      :label="$t('remoteConnection.dialog.privateKey')"
                    />
                  </div>
                  <div v-if="remoteConnection.connection !== 'VNC'">
                    <v-text-field
                      id="iiotRemoteConnectionDialogUsernameInput"
                      v-model="remoteConnection.username"
                      :label="$t('remoteConnection.dialog.username')"
                      :rules="rulesForUsername"
                      validate-on-blur
                    />
                    <v-text-field
                      v-if="!remoteConnection.privateKeyFlag"
                      id="iiotRemoteConnectionScreenDialogPasswordInput"
                      v-model="remoteConnection.password"
                      :label="$t('remoteConnection.dialog.password')"
                      :rules="rulesMaxLength"
                      :type="typePasswordField"
                      validate-on-blur
                      @focus="handleType"
                      @blur="handleType"
                    />
                    <v-textarea
                      v-if="remoteConnection.privateKeyFlag"
                      id="iiotRemoteConnectionDialogPrivateHostKey"
                      v-model="remoteConnection.privateKey"
                      :label="$t('remoteConnection.dialog.privateHostKey')"
                      :rules="rulesRequiredField"
                      class="mt-6"
                      outlined
                      validate-on-blur
                      auto-grow
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </div>
        <v-card-actions>
          <nerve-button
            id="iiotRemoteConnectionScreenDialogCancelButton"
            :text="$t('baseForm.cancelBtn')"
            type-of-btn="cancel"
            size="normal"
            class="pl-4"
            autofocus
            @click-event="close()"
          />
          <nerve-button
            v-if="isNew ? hasCreatePermission : hasEditPermission"
            id="iiotRemoteConnectionScreenDialogAddButton"
            :text="edit ? $t('baseForm.updateBtn') : $t('baseForm.add')"
            type-of-btn="action"
            size="normal"
            autofocus
            @click-event="save()"
          />
        </v-card-actions>
      </v-card>
    </nerve-dialog>
  </v-form>
</template>

<script>
import { NerveButton, NerveDialog } from 'nerve-ui-components';
import ConnectionScreenModel from '@/model/node/connectionScreen.model';
import {
  MAX_LENGTH_NAME,
  MIN_VALUE_OF_PORT,
  MAX_VALUE_OF_PORT,
  REMOTE_CONNECTION_PORT_SSH,
  REMOTE_CONNECTION_PORT_VNC,
  REMOTE_CONNECTION_PORT_RDP,
  VALIDATION_REGEX,
  REMOTE_CONNECTION,
} from '@/constants';

export default {
  components: { NerveButton, NerveDialog },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    versionModel: {
      type: Object,
      default: () => {},
    },
    versionType: {
      type: String,
      default: '',
    },
    hasEditPermission: {
      type: Boolean,
      default: false,
    },
    hasCreatePermission: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localAcknowledgment: [
        {
          text: this.$t('remoteConnection.dialog.yes'),
          value: 'Yes',
          id: 'iiotRemoteConnectionDialogYesLocalAcknowledgmentOption',
        },
        {
          text: this.$t('remoteConnection.dialog.no'),
          value: 'No',
          id: 'iiotRemoteConnectionDialogNoLocalAcknowledgmentOption',
        },
      ],
      connectionType: [
        { text: this.$t('remoteConnection.dialog.connectionTypeSSH') },
        { text: this.$t('remoteConnection.dialog.connectionTypeVNC') },
        { text: this.$t('remoteConnection.dialog.connectionTypeRDP') },
      ],
      securityMode: [
        { value: 'any', text: this.$t('remoteConnection.securityMode.any') },
        { value: 'nla', text: this.$t('remoteConnection.securityMode.nla') },
        { value: 'rdp', text: this.$t('remoteConnection.securityMode.rdp') },
        { value: 'tls', text: this.$t('remoteConnection.securityMode.tls') },
        { value: '', text: this.$t('remoteConnection.securityMode.unspecified') },
      ],
      cursor: [
        { value: 'local', text: this.$t('remoteConnection.cursor.local') },
        { value: 'remote', text: this.$t('remoteConnection.cursor.remote') },
        { value: '', text: this.$t('remoteConnection.cursor.unspecified') },
      ],
      swapRedBlue: [true, false],
      readOnly: [true, false],
      ignoreServerCertificate: false,
      valid: false,
      show: false,
      MAX_LENGTH_NAME,
      MIN_VALUE_OF_PORT,
      MAX_VALUE_OF_PORT,
      REMOTE_CONNECTION_PORT_SSH,
      REMOTE_CONNECTION_PORT_VNC,
      REMOTE_CONNECTION_PORT_RDP,
      REMOTE_CONNECTION,
      connectionModel: new ConnectionScreenModel(),
      model: '',
      typePasswordField: 'text',
      hidePassword: true,
      isMarginVisible: false,
      // oldConnection: {},
    };
  },
  computed: {
    rulesForPort() {
      return [
        (value) =>
          (value > MIN_VALUE_OF_PORT && value <= MAX_VALUE_OF_PORT) || this.$t('remoteConnection.portNumbersMessage'),
      ];
    },
    rulesForNumberOfConnections() {
      return [
        (value) => !!value || this.$t('remoteConnection.required'),
        (value) =>
          (value >= REMOTE_CONNECTION.NUMBER_OF_CONNECTIONS_MIN_VALUE &&
            value <= REMOTE_CONNECTION.NUMBER_OF_CONNECTIONS_MAX_VALUE) ||
          this.$t('remoteConnection.numberOfConnectionsLimitation'),
      ];
    },
    rulesForAutoRetry() {
      return [
        (value) => !!value || this.$t('remoteConnection.required'),
        (value) =>
          (value >= REMOTE_CONNECTION.AUTO_RETRY_MIN_VALUE && value <= REMOTE_CONNECTION.AUTO_RETRY_MAX_VALUE) ||
          this.$t('remoteConnection.autoRetryLimitation'),
      ];
    },
    rulesRequiredField() {
      return [(value) => !!value || this.$t('remoteConnection.required')];
    },
    rulesMaxLength() {
      return [(value) => value.length <= MAX_LENGTH_NAME || this.$t('remoteConnection.rulesForInputField')];
    },
    rulesForInputFieldNotRequired() {
      return [(value) => value.length <= MAX_LENGTH_NAME || this.$t('remoteConnection.rulesForInputField')];
    },
    rulesForInputField() {
      return [
        (value) => !!value || this.$t('remoteConnection.required'),
        (value) => value.length <= MAX_LENGTH_NAME || this.$t('remoteConnection.rulesForInputField'),
      ];
    },
    rulesForName() {
      return [
        (value) => !!value || this.$t('remoteConnection.required'),
        (value) =>
          !VALIDATION_REGEX.STRING_CONTAINS_ONLY_SPACES.test(value) ||
          this.$t('remoteConnection.rcNameContainOnlySpaces'),
        (value) =>
          VALIDATION_REGEX.NO_CONTROL_CHARACTERS.test(value) ||
          this.$t('remoteConnection.rcNameContainControlCharacter'),
      ];
    },
    rulesForHostname() {
      return [
        (value) => !!value || this.$t('remoteConnection.required'),
        (value) => value.length <= MAX_LENGTH_NAME || this.$t('remoteConnection.rulesForInputField'),
        (value) => VALIDATION_REGEX.RC_HOSTNAME.test(value) || this.$t('remoteConnection.rcNameHostnamePattern'),
      ];
    },
    rulesForUsername() {
      return [
        (value) => value.length <= MAX_LENGTH_NAME || this.$t('remoteConnection.rulesForInputField'),
        (value) => VALIDATION_REGEX.DOCKER_USERNAME.test(value) || this.$t('workloadVersion.dockerUsernamePattern'),
      ];
    },
    remoteConnection() {
      return this.$store.getters['remote-connection/getScreenRc'];
    },
    screenDialog() {
      return this.$store.getters['remote-connection/rcScreenDialog'];
    },
    nodeModel() {
      return this.$store.getters['nodes/getNodeData'];
    },
    serviceNames() {
      const services = this.versionModel?.services.map((svc) => svc.serviceName);
      return services;
    },
    getOldVersionRemoteConnections() {
      return this.$store.getters['workloads/getOldVersionRemoteConnections'];
    },
    getOldNodeRemoteConnections() {
      return this.$store.getters['nodes/getOldNodeRemoteConnections'];
    },
    passwordIsRequired() {
      const oldConnection = this.versionType
        ? this.getOldVersionRemoteConnections.find((rc) => rc._id === this.remoteConnection._id)
        : this.getOldNodeRemoteConnections.find((rc) => rc._id === this.remoteConnection._id);

      return !this.remoteConnection?._id || oldConnection?.connection !== this.remoteConnection?.connection;
    },
  },
  created() {
    // eslint-disable-next-line prefer-destructuring
    this.model = window.location.pathname.split('/')[1];
  },
  beforeMount() {
    // eslint-disable-next-line prefer-destructuring
    this.workloadId = window.location.pathname.split('/')[2];
    this.typePasswordField = 'password';
  },
  methods: {
    close() {
      this.$store.dispatch('remote-connection/set_rc_screen_dialog', false);
      this.$refs.remoteConnectionForm.resetValidation();
    },
    async save() {
      try {
        if (!this.$refs.remoteConnectionForm.validate()) {
          return;
        }
        if (!this.remoteConnection.privateKeyFlag) {
          this.remoteConnection.privateKey = '';
        }
        if (this.model === 'nodes') {
          this.nodeModel.connection = this.remoteConnection;
          this.nodeModel.delete = false;
          this.nodeModel.connection.type = 'SCREEN';
          await this.$store.dispatch('remote-connection/update_node', { nodeData: this.nodeModel });
        } else if (this.model === 'workloads') {
          this.remoteConnection.type = 'SCREEN';
          await this.$store.dispatch('remote-connection/add_update_remote_connection', {
            connection: this.remoteConnection,
          });
          await this.$store.dispatch('workloads/add_edit_rc_from_workload', { connection: this.remoteConnection });
          await this.$store.dispatch('workloads/form_has_changed');
        }
        this.close();
      } catch (e) {
        this.$log.debug(e);
      }
    },
    connectionTypeChange() {
      if (this.remoteConnection.password === '') {
        this.typePasswordField = 'text';
      } else {
        this.typePasswordField = 'password';
      }

      this.hidePassword = true;
      // eslint-disable-next-line default-case
      switch (this.remoteConnection.connection) {
        case 'SSH':
          this.remoteConnection.port = REMOTE_CONNECTION_PORT_SSH;
          break;
        case 'VNC':
          this.remoteConnection.port = REMOTE_CONNECTION_PORT_VNC;
          break;
        case 'RDP':
          this.remoteConnection.port = REMOTE_CONNECTION_PORT_RDP;
          break;
      }
    },
    handleType(event) {
      setTimeout(() => {
        const { srcElement, type } = event;
        const { value } = srcElement;
        if (type === 'blur' && !value) {
          this.typePasswordField = 'text';
        } else {
          this.typePasswordField = 'password';
        }
      }, 1);
    },
    onResize() {
      this.isMarginVisible = window.innerWidth < 1264;
    },
  },
};
</script>
<style scoped>
::v-deep .v-textarea textarea {
  max-width: 200px;
}
.rcDialog {
  min-width: 1000px !important;
  min-height: 350px !important;
}
.rc-screen-dialog {
  max-height: 1000px !important;
  min-height: 320px !important;
}
</style>
